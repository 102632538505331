<template>
  <div
    class="navbar"
    v-bind:style="mostrarBlur == true ? { 'backdrop-filter': 'blur(5px)' } : ''"
  >
    <div class="navbar-mobile-menu">
      <img
        v-show="!mostrarNarvbar"
        src="../assets/img/menu_white.png"
        @click="mostrarNavbar()"
      />
      <img
        v-show="mostrarNarvbar"
        src="../assets/img/close_white.png"
        @click="ocultarNavbar()"
      />
    </div>
    <div class="navbar-container" ref="navbar">
      <div class="navbar-logo-container">
        <img class="navbar-logo" src="../assets/img/logo.png" />
      </div>
      <div class="navbar-user-data">
        <div class="navbar-user-img-container">
          <img
            ref="usuarioImagen"
            class="navbar-user-img"
            v-if="$usuario.imagen != null"
            v-bind:src="imageSrc"
          />
          <img
            class="navbar-user-img-edit"
            src="../assets/img/edit_circle.png"
            @click="mostrarImagenMenu = true"
          />
          <div
            class="navbar-user-img-popup-mask"
            v-show="mostrarImagenMenu"
            @click="mostrarImagenMenu = false"
          ></div>
          <transition name="fade-fast">
            <div class="navbar-user-img-popup" v-show="mostrarImagenMenu">
              <input
                class="app-hide"
                type="file"
                ref="usuarioImagenUpload"
                @change="changeImagen()"
              />
              <p class="navbar-user-img-popup-p" @click="cambiarImagen()">
                Elegir imagen
              </p>
              <p
                class="navbar-user-img-popup-p"
                style="color: #ff2020"
                @click="eliminarImagen()"
              >
                Eliminar
              </p>
            </div>
          </transition>
        </div>
        <div>
          <p class="navbar-user-nombre" style="cursor: pointer">
            {{ $usuario.nombre }}
          </p>
          <p class="navbar-user-email" style="cursor: pointer">
            {{ $usuario.email }}
          </p>
        </div>
      </div>
      <div>
        <ul class="navbar-ul">
          <li
            class="navbar-item"
            v-show="getRol().rol != 'Distribuidor' && getRol().rol != 'Agro'"
          >
            <img
              src="../assets/img/navbar/permisos.svg"
              v-bind:style="path == 'permisos' ? { opacity: '1' } : ''"
            />
            <p
              class="navbar-li"
              @click="irA('permisos')"
              :class="{ 'router-link-exact-active': path == 'permisos' }"
            >
              Permisos
            </p>
          </li>
          <li
            class="navbar-item navbar-li-volver"
            v-show="getRol().rol != 'Distribuidor'"
          >
            <img src="../assets/img/navbar/home.svg" style="opacity: 1" />
            <p class="navbar-li router-link-exact-active" @click="irA('')">
              Volver a Home
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Velocity from "velocity-animate";

export default {
  name: "navbar",
  components: {},
  data() {
    return {
      path: "",
      mostrarBlur: false,
      mostrarImagenMenu: false,
      imagenTemp: null,
      mostrarNarvbar: false,
    };
  },
  created() {
    this.$eventHub.$on("navbar-to", () => this.actualizar());
    this.$eventHub.$on("update", () => this.actualizar());
    this.$eventHub.$on("navbar-actualizar", () => this.actualizar());
    this.$eventHub.$on(
      "navbar-blur",
      (boolean) => (this.mostrarBlur = boolean)
    );
    this.actualizar();
  },
  mounted() {
    this.actualizar();
  },
  computed: {
    imageSrc: function () {
      return this.$usuario != null &&
        this.$usuario.imagen != null &&
        this.$usuario.imagen.length < 100
        ? this.$localurl + "/" + this.$usuario.imagen
        : this.imagenTemp;
    },
  },
  methods: {
    actualizar() {
      this.path = window.location.pathname.split("/").reverse()[0];
      this.$vnode.key++;
    },
    irA(texto) {
      this.$router.push("/" + texto);
    },
    getRol() {
      return this.$usuario.rolDefault;
    },
    logout() {
      this.$authService.logout();
    },
    cambiarImagen() {
      this.$refs.usuarioImagenUpload.click();
    },
    changeImagen() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function (e) {
        that.imagenTemp = e.target.result;
        that.$nextTick(() => {
          that.$refs.usuarioImagen.src = e.target.result;
        });
        that.$usuario.imagen = e.target.result.replace(
          /^data:image\/(png|jpg|jpeg);base64,/,
          ""
        );
        that.actualizarUsuario();
      };
      reader.readAsDataURL(this.$refs.usuarioImagenUpload.files[0]);
    },
    eliminarImagen() {
      this.$usuario.imagen = null;
      this.$refs.usuarioImagen.src = null;
      this.actualizarUsuario();
    },
    actualizarUsuario() {
      this.mostrarLoading = true;
      this.mostrarImagenMenu = false;
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/usuario/self", this.$usuario)
        .then(function (response) {
          that.$usuario = response.data;
          that.mostrarLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    mostrarNavbar() {
      this.mostrarNarvbar = true;
      Velocity(this.$refs.navbar, {
        top: window.innerHeight,
      });
    },
    ocultarNavbar() {
      this.mostrarNarvbar = false;
      Velocity(this.$refs.navbar, {
        top: 0,
      });
    },
  },
};
</script>

<style>
@import "../assets/css/components/navbar.css";
</style>
